<template>
  <div class="mainform">
    <div class="form">
      <el-form  class="content bascform" ref="addpayplan" :model="form"  label-width="110px">
        <div class="col col4">
          <el-form-item label="考评指标代码" prop="evaluationCode" label-width="110px">
            <el-input v-model="form.evaluationCode" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="考评指标描述" prop="evaluationName" label-width="110px">
            <el-input v-model="form.evaluationName" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="是否启用" prop="status" label-width="110px">
            <el-checkbox v-model="form.status"></el-checkbox>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'

export default {
  name: 'EvaluationAddDialog',
  components: { ...Component },
  data: function () {
    return {
      form: {}
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
@import '@/assets/css/elForm.scss';
.mainform{
  .form{
    padding: 0px 0px;
  }
}
</style>
